// composables/useIntersectionObserver.js
import { ref, onMounted, onUnmounted } from 'vue'

export function useIntersectionObserver(options = {}) {
  const isNearViewport = ref(false)
  const isIntersecting = ref(false)

  if (process.client) {
    const nearObserver = ref(null)
    const intersectObserver = ref(null)
    const elementRef = ref(null)

    onMounted(() => {
      nearObserver.value = new IntersectionObserver(([entry]) => {
        isNearViewport.value = entry.isIntersecting
      }, { ...options, rootMargin: options.loadOffset || '500px',root:document.querySelector('.main') })

      intersectObserver.value = new IntersectionObserver(([entry]) => {
        isIntersecting.value = entry.isIntersecting
      }, { ...options, rootMargin: '0px' })

      if (elementRef.value) {
        nearObserver.value.observe(elementRef.value)
        intersectObserver.value.observe(elementRef.value)
      }
    })

    onUnmounted(() => {
      if (nearObserver.value) nearObserver.value.disconnect()
      if (intersectObserver.value) intersectObserver.value.disconnect()
    })

    return { isNearViewport, isIntersecting, elementRef }
  }

  return { isNearViewport, isIntersecting, elementRef: ref(null) }
}
